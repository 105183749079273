/* You can add global styles to this file, and also import other style files */
.text-end {
    text-align: end;
}

nz-page-header-title {
    color: #19578b !important;
}

.first-tab-title {
    padding-bottom: 60px;
}


.events-search {
    padding-top: 100px;
    padding-bottom: 40px !important;
}

input,
textarea {
    direction: rtl;
}

@font-face {
    font-family: 'AvenirArabicBlack';
    src: url('/assets/fonts/AvenirArabic-Black.otf');
}

@font-face {
    font-family: 'AvenirArabicBook';
    src: url('/assets/fonts/AvenirArabic-Book.otf');
}

@font-face {
    font-family: 'AvenirArabicHeavy';
    src: url('/assets/fonts/AvenirArabic-Heavy.otf');
}

@font-face {
    font-family: 'AvenirArabicLight';
    src: url('/assets/fonts/AvenirArabic-Light.otf');
}

@font-face {
    font-family: 'AvenirArabicLight1';
    src: url('/assets/fonts/AvenirArabic-Light1.otf');
}

@font-face {
    font-family: 'AvenirArabicMedium';
    src: url('/assets/fonts/AvenirArabic-Medium.otf');
}

@font-face {
    font-family: 'VisbyRoundCFBold';
    src: url('/assets/fonts/VisbyRoundCF-Bold.ttf');
}

@font-face {
    font-family: 'VisbyRoundCFBoldOblique';
    src: url('/assets/fonts/VisbyRoundCF-BoldOblique.ttf');
}

@font-face {
    font-family: 'VisbyRoundCFDemiBoldOblique';
    src: url('/assets/fonts/VisbyRoundCF-DemiBoldOblique.ttf');
}

@font-face {
    font-family: 'VisbyRoundCFExtraBold';
    src: url('/assets/fonts/VisbyRoundCF-ExtraBold.ttf');
}

@font-face {
    font-family: 'VisbyRoundCFExtraBoldOblique';
    src: url('/assets/fonts/VisbyRoundCF-ExtraBoldOblique.ttf');
}

@font-face {
    font-family: 'VisbyRoundCFExtraLight';
    src: url('/assets/fonts/VisbyRoundCF-ExtraLight.ttf');
}

@font-face {
    font-family: 'VisbyRoundCFExtraLightOblique';
    src: url('/assets/fonts/VisbyRoundCF-ExtraLightOblique.ttf');
}

@font-face {
    font-family: 'VisbyRoundCFHeavy';
    src: url('/assets/fonts/VisbyRoundCF-Heavy.ttf');
}

@font-face {
    font-family: 'VisbyRoundCFHeavyOblique';
    src: url('/assets/fonts/VisbyRoundCF-HeavyOblique.ttf');
}

@font-face {
    font-family: 'VisbyRoundCFLight';
    src: url('/assets/fonts/VisbyRoundCF-Light.ttf');
}

@font-face {
    font-family: 'VisbyRoundCFLightOblique';
    src: url('/assets/fonts/VisbyRoundCF-LightOblique.ttf');
}

@font-face {
    font-family: 'VisbyRoundCFMedium';
    src: url('/assets/fonts/VisbyRoundCF-Medium.ttf');
}

@font-face {
    font-family: 'VisbyRoundCFMediumOblique';
    src: url('/assets/fonts/VisbyRoundCF-MediumOblique.ttf');
}

@font-face {
    font-family: 'VisbyRoundCFOblique';
    src: url('/assets/fonts/VisbyRoundCF-Oblique.ttf');
}

@font-face {
    font-family: 'VisbyRoundCFRegular';
    src: url('/assets/fonts/VisbyRoundCF-Regular.ttf');
}

.mt-50 {
    margin-top: 50px;
}

th,
td {
    text-align: right !important;
}

nz-page-header-title {
    color: #282A67 !important;
}
/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
